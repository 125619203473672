import "./App.css";
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import { Container, Box } from "@mui/material";
import { Swap } from "./swap";

require("@solana/wallet-adapter-react-ui/styles.css");

function App() {
  return (
    <div
      style={{
        backgroundImage: "url(/cetswep2.jpg)",
        backgroundSize: "cover",
        minHeight: "100vh", // Ensure content takes up full screen height
        backgroundPosition: "center",
        overflow: "hidden", // Hide content overflow
        position: "relative", // Relative positioning
      }}
    >
      <Container maxWidth="sm" sx={{ textAlign: "center", my: 3 }}>
        <Box display={"flex"} justifyContent={"space-around"} alignItems={"center"}>
          <Box color={"white"} sx={{ cursor: "pointer", fontWeight: 600 }}>
            $CETSWEP
          </Box>
          <Box display={"flex"} alignItems={"center"} gap={1}>
            <a
              href="https://twitter.com/cetswep"
              target="_blank"
              style={{ textDecoration: "underline", color: "white" }}
            >
              [Twitter]
            </a>
            <a
              href="https://t.me/cetswep"
              target="_blank"
              style={{ textDecoration: "underline", color: "white" }}
            >
              [Telegram]
            </a>
            <WalletMultiButton />
          </Box>
        </Box>
        <Swap />
      </Container>
    </div>
  );
}

export default App;

import { rpc } from "./config";

export async function getAsset(mint) {
  const response = await fetch(rpc, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      jsonrpc: "2.0",
      id: "my-id",
      method: "getAsset",
      params: {
        id: mint,
        displayOptions: {
          showFungible: true, //return details about a fungible token
        },
      },
    }),
  });
  const { result } = await response.json();
  return result;
}

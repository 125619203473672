import React, { useEffect, useState } from "react";
import { useWallet } from "@solana/wallet-adapter-react";
import {
  Button,
  Typography,
  Modal,
  Box,
  InputBase,
  Avatar,
  TextField,
  List,
  ListItemButton,
  ListItemText,
  ListItemAvatar,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import { getAsset } from "./getAsset";
import { searchAssets } from "./searchAssets";
import { jupSwap } from "./jupswap";

export const Swap = () => {
  const { publicKey, sendTransaction } = useWallet();

  const [assets, setAssets] = useState();
  const [inputBalance, setInputBalance] = useState(0);
  const [outputBalance, setOutputBalance] = useState(0);
  const [slippage, setSlippage] = useState(1);
  const [buyCurrency, setBuyCurrency] = useState("EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v");
  const [buySymbol, setBuySymbol] = useState("USDC");
  const [buyIcon, setBuyIcon] = useState(
    "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v/logo.png"
  );
  const [buyAmount, setBuyAmount] = useState("");
  const [buyDecimal, setBuyDecimal] = useState(6);
  const [sellCurrency, setSellCurrency] = useState("So11111111111111111111111111111111111111112");
  const [sellSymbol, setSellSymbol] = useState("SOL");
  const [sellIcon, setSellIcon] = useState(
    "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/So11111111111111111111111111111111111111112/logo.png"
  );
  const [sellDecimal, setSellDecimal] = useState(9);
  const [sellAmount, setSellAmount] = useState("");
  const [openSell, setOpenSell] = useState(false);
  const [openBuy, setOpenBuy] = useState(false);
  const [status, setStatus] = useState("");

  const [searchResults, setSearchResults] = useState([]);
  const [jup, setJup] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    async function getA() {
      if (publicKey) {
        const res = await searchAssets(publicKey);
        setAssets(res);
      }
    }
    getA();
  }, [publicKey]);

  useEffect(() => {
    const pattern = /^[a-zA-Z0-9]{41,}$/;
    if (assets && pattern.test(buyCurrency)) {
      getBalance(buyCurrency, "input");
    }
    if (assets && pattern.test(sellCurrency)) {
      getBalance(sellCurrency, "output");
    }
    getRate();
  }, [buyCurrency, sellCurrency, sellAmount]);

  useEffect(() => {
    setSearchResults(jup);
    const filter = jup.filter((item) => {
      return (
        item.name.toLowerCase().startsWith(searchQuery) ||
        item.symbol.toLowerCase().startsWith(searchQuery)
      );
    });

    setSearchResults(filter);

    const pattern = /^[a-zA-Z0-9]{41,}$/;
    if (pattern.test(searchQuery)) {
      async function getA() {
        const res = await getAsset(searchQuery);
        if (res?.content) {
          const results = [
            {
              symbol: res?.content.metadata.symbol,
              decimals: res?.token_info.decimals,
              logoURI: res?.content.links.image,
              address: searchQuery,
            },
          ];
          setSearchResults(results);
        }
      }
      getA();
    }
  }, [searchQuery]);

  const handleOpenBuy = async () => {
    setOpenBuy(true);
    const resp = await fetch("https://token.jup.ag/strict");
    const list = await resp.json();
    setSearchQuery("");
    setSearchResults(list);
    setJup(list);
  };

  const handleCloseBuy = () => {
    setOpenBuy(false);
    setSearchQuery("");
    setSearchResults([]);
  };

  const handleOpenSell = async () => {
    setOpenSell(true);
    const resp = await fetch("https://token.jup.ag/strict");
    const list = await resp.json();
    setSearchQuery("");
    setSearchResults(list);
    setJup(list);
  };

  const handleCloseSell = () => {
    setOpenSell(false);
    setSearchQuery("");
    setSearchResults([]);
  };

  const handleBuySelect = (item) => {
    setBuyCurrency(item.address);
    setBuyIcon(item.logoURI);
    setBuyDecimal(item.decimals);
    setBuySymbol(item.symbol);
    setOpenBuy(false);
    setSearchQuery("");
    setSearchResults([]);
  };

  const handleSellSelect = (item) => {
    setSellCurrency(item.address);
    setSellIcon(item.logoURI);
    setSellSymbol(item.symbol);
    setSellDecimal(item.decimals);
    setOpenSell(false);
    setSearchQuery("");
    setSearchResults([]);
  };

  const handleSwap = async () => {
    const pattern = /^[a-zA-Z0-9]{41,}$/;
    if (publicKey && pattern.test(buyCurrency) && pattern.test(sellCurrency) && sellAmount > 0) {
      setStatus("processing");
      let swapstatus = await jupSwap(
        publicKey,
        sendTransaction,
        buyCurrency,
        sellCurrency,
        Math.floor(sellAmount * Math.pow(10, sellDecimal)),
        slippage
      );
      setStatus(swapstatus);
    }
  };

  async function getRate() {
    const pattern = /^[a-zA-Z0-9]{41,}$/;
    if (publicKey && pattern.test(buyCurrency) && pattern.test(sellCurrency) && sellAmount > 0) {
      const amount = sellAmount * Math.pow(10, sellDecimal);

      const quoteResponse = await fetch(
        `https://quote-api.jup.ag/v6/quote?inputMint=${sellCurrency}&outputMint=${buyCurrency}&amount=${Math.floor(
          amount
        )}&slippageBps=${slippage * 100}`
      );
      const qr = await quoteResponse.json();
      if (qr.error) {
        console.log(qr.error);
        return;
      }
      setBuyAmount(qr.outAmount / Math.pow(10, buyDecimal));
    }
  }

  async function getBalance(mint, type) {
    const holding = assets?.items.filter((item) => item.id === mint);
    if (type === "input") {
      if (mint === "So11111111111111111111111111111111111111112") {
        setInputBalance(assets?.nativeBalance.lamports / Math.pow(10, 9));
      } else if (holding?.length > 0) {
        setInputBalance(
          holding[0].token_info.balance / Math.pow(10, holding[0].token_info.decimals)
        );
      } else {
        setInputBalance("0");
      }
    } else {
      if (mint === "So11111111111111111111111111111111111111112") {
        setOutputBalance(assets?.nativeBalance.lamports / Math.pow(10, 9));
      } else if (holding?.length > 0) {
        setOutputBalance(
          holding[0].token_info.balance / Math.pow(10, holding[0].token_info.decimals)
        );
      } else {
        setOutputBalance("0");
      }
    }
  }

  return (
    <Box my={3}>
      <Box mx={2} sx={{ textAlign: "right", color: "white" }}>
        {outputBalance} {sellSymbol}
      </Box>
      <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
        gap={2}
        bgcolor={"#e0f7fa"}
        p={2}
        m={2}
        px={4}
        borderRadius={9}
      >
        <ArrowCircleRightIcon color="error" />
        <Typography>cet gib </Typography>
        <InputBase
          size="small"
          placeholder="0.00"
          type="number"
          sx={{ background: "white", px: 2, pt: 1, borderRadius: 9 }}
          value={sellAmount}
          onChange={(e) => setSellAmount(e.target.value)}
        />
        <Box
          display={"flex"}
          alignItems={"center"}
          onClick={handleOpenSell}
          style={{ cursor: "pointer" }}
        >
          <Avatar alt={sellSymbol} src={sellIcon} sx={{ width: 20, height: 20, mr: 1 }} />
          <Typography variant="body1">{sellSymbol}</Typography>
          <ExpandMoreIcon fontSize="small" />
        </Box>
      </Box>
      <Box mx={2} sx={{ textAlign: "right", color: "white" }}>
        {inputBalance} {buySymbol}
      </Box>
      <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
        gap={2}
        bgcolor={"#e0f7fa"}
        p={2}
        m={2}
        px={4}
        borderRadius={9}
      >
        <ArrowCircleLeftIcon color="success" />
        <Typography>cet tek </Typography>
        <InputBase
          size="small"
          placeholder="0.00"
          type="number"
          sx={{ background: "white", px: 2, pt: 1, borderRadius: 9 }}
          value={buyAmount}
          onChange={(e) => setBuyAmount(e.target.value)}
        />
        <Box
          display={"flex"}
          alignItems={"center"}
          onClick={handleOpenBuy}
          style={{ cursor: "pointer" }}
        >
          <Avatar alt={buySymbol} src={buyIcon} sx={{ width: 20, height: 20, mr: 1 }} />
          <Typography variant="body1">{buySymbol}</Typography>
          <ExpandMoreIcon fontSize="small" />
        </Box>
      </Box>
      <Box sx={{ textAlign: "right", color: "white" }}>
        slipig{" "}
        <InputBase
          size="small"
          type="number"
          sx={{ background: "white", px: 2, pt: 1, borderRadius: 9, width: 80 }}
          value={slippage}
          onChange={(e) => setSlippage(e.target.value)}
        />
      </Box>
      <Button variant="contained" onClick={handleSwap} sx={{ background: "black" }}>
        cet swep
      </Button>
      {status ? (
        <Box textAlign={"end"}>
          <Button
            sx={{
              m: 2,
              bgcolor: "#e0f7fa",
              p: 2,
              px: 5,
              borderRadius: 9,
              textAlign: "end",
            }}
            size="small"
            disabled
          >
            {status}
          </Button>
        </Box>
      ) : (
        <></>
      )}

      {/* Buy Modal */}
      <Modal
        open={openBuy}
        onClose={handleCloseBuy}
        aria-labelledby="modal-buy-title"
        aria-describedby="modal-buy-description"
      >
        {/* Modal content for buying */}
        <Box
          borderRadius={9}
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "#e0f7fa",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Box display={"flex"} alignItems={"center"} gap={2}>
            <SearchIcon fontSize="small" color="info" />
            <InputBase
              fullWidth
              placeholder="Enter Token Address"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            <Button onClick={handleCloseBuy} size="small" color="info">
              <CloseIcon />
            </Button>
          </Box>

          <List component="nav" sx={{ maxHeight: "60vh", overflowY: "auto" }}>
            {searchResults.map((result) => (
              <ListItemButton key={result.address} onClick={() => handleBuySelect(result)}>
                <ListItemAvatar>
                  <Avatar src={result.logoURI} />
                </ListItemAvatar>
                <ListItemText
                  primary={result.symbol}
                  secondary={
                    <React.Fragment>
                      <Typography
                        sx={{ display: "inline" }}
                        component="span"
                        variant="caption"
                        color="text.secondary"
                      >
                        {result.address.substring(0, 4)}...
                        {result.address.substring(result.address.length - 4)}
                      </Typography>
                    </React.Fragment>
                  }
                />
              </ListItemButton>
            ))}
          </List>
        </Box>
      </Modal>
      {/* Sell Modal */}
      <Modal
        open={openSell}
        onClose={handleCloseSell}
        aria-labelledby="modal-buy-title"
        aria-describedby="modal-buy-description"
      >
        <Box
          borderRadius={9}
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "#e0f7fa",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Box display={"flex"} alignItems={"center"} gap={2}>
            <SearchIcon fontSize="small" color="info" />
            <InputBase
              fullWidth
              placeholder="Enter Token Address"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            <Button onClick={handleCloseSell} size="small" color="info">
              <CloseIcon />
            </Button>
          </Box>

          <List component="nav" sx={{ maxHeight: "60vh", overflowY: "auto" }}>
            {searchResults.map((result) => (
              <ListItemButton key={result.address} onClick={() => handleSellSelect(result)}>
                <ListItemAvatar>
                  <Avatar src={result.logoURI} />
                </ListItemAvatar>
                <ListItemText
                  primary={result.symbol}
                  secondary={
                    <React.Fragment>
                      <Typography
                        sx={{ display: "inline" }}
                        component="span"
                        variant="caption"
                        color="text.secondary"
                      >
                        {result.address.substring(0, 4)}...
                        {result.address.substring(result.address.length - 4)}
                      </Typography>
                    </React.Fragment>
                  }
                />
              </ListItemButton>
            ))}
          </List>
        </Box>
      </Modal>
    </Box>
  );
};
